<template>
  <v-form v-model="valid" ref="quizform" v-if="quiz" lazy-validation>
    <v-card
      class="d-flex flex-column pa-4 pa-md-8 quizform rounded-lg"
      :outlined="$vuetify.breakpoint.mdAndUp"
      elevation="0"
      :class="!valid ? 'invalid' : ''"
    >
      <v-card-title class="text-h5 primary--text font-weight-black">{{ quizid ? "Edit " : "New " }} Quiz</v-card-title>
      <quiz-type-card :quiz="quiz"> </quiz-type-card>
      <v-row justify="space-between" class="pa-4 my-2">
        <v-col cols="12">
          <v-card-subtitle class="overline pa-0 primary--text font-weight-bold">Basic Details</v-card-subtitle>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            label="Title"
            :value="quiz.title"
            @blur="updateQuiz({ title: $event.target.value })"
            :rules="titlerule"
            clearable
          ></v-text-field>

          <v-text-field
            label="Description"
            :value="quiz.description"
            @blur="updateQuiz({ description: $event.target.value })"
            :rules="descrule"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-switch
            v-if="quiz.timerenabled"
            :input-value="quiz.advanced"
            @change="updateQuiz({ advanced: $event })"
            inset
            :label="`Powerups ${quiz.advanced ? 'Enabled' : 'Disabled'}`"
          ></v-switch>
          <v-switch
            :input-value="defaults"
            @change="defaults = !defaults"
            inset
            :label="`Question Defaults ${defaults ? 'Enabled' : 'Disabled'} `"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row no-gutters class="background pa-4 mb-2" v-if="defaults">
        <v-col cols="12">
          <v-card-subtitle class="overline pa-0 primary--text font-weight-bold"
            >Default For New Question</v-card-subtitle
          >
        </v-col>
        <v-col cols="12" md="3" v-if="quiz.timerenabled">
          <v-radio-group :value="quiz.defaultquestiontype" mandatory class="mx-4" :row="$vuetify.breakpoint.smAndDown">
            <v-radio dense value="T" @click="updateQuiz({ defaultquestiontype: 'T' })" label="True/False"> </v-radio>
            <v-radio dense value="M" @click="updateQuiz({ defaultquestiontype: 'M' })" label="Multi-Choice"> </v-radio>
            <v-radio dense value="P" @click="updateQuiz({ defaultquestiontype: 'P' })" label="Poll"> </v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" md="9" class="d-flex flex-column flex-md-row">
          <v-slider
            v-if="quiz.timerenabled"
            hide-details
            label="Time (in secs)"
            :value="quiz.timer"
            @change="updateQuiz({ timer: $event })"
            step="5"
            min="0"
            max="300"
            thumb-label="always"
            class="mt-6"
          ></v-slider>
          <v-slider
            v-if="quiz.timerenabled"
            hide-details
            label="Points"
            :value="quiz.points"
            @end="updateQuiz({ points: $event })"
            step="10"
            min="10"
            max="100"
            thumb-label="always"
            class="mt-6"
          >
          </v-slider>
          <v-slider
            hide-details
            label="Answer Choices"
            :value="quiz.choices"
            @end="updateQuiz({ choices: $event })"
            step="1"
            min="2"
            max="6"
            thumb-label="always"
            class="mt-6"
          >
          </v-slider>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="quiz.advanced" class="background pa-4 mb-2">
        <v-card-subtitle class="overline pa-0 primary--text font-weight-bold">Powerups</v-card-subtitle>
        <v-col cols="12" class="d-flex flex-column flex-md-row justify-space-between">
          <v-switch
            :input-value="quiz.lifelines"
            inset
            color="primary"
            label="Answer Hints"
            value="hint"
            class="mr-4"
            @change="updateQuiz({ lifelines: $event })"
          ></v-switch>
          <v-switch
            :input-value="quiz.lifelines"
            inset
            color="primary"
            label="Erase Incorrect"
            value="eraser"
            class="mr-4"
            @change="updateQuiz({ lifelines: $event })"
          ></v-switch>
          <v-slider
            label="Max Powerups (Per Participant)"
            :value="quiz.maxlifelines"
            @change="updateQuiz({ maxlifelines: $event })"
            :disabled="quiz.lifelines.length === 0"
            step="1"
            min="2"
            max="10"
            thumb-label="always"
            class="mt-4"
          ></v-slider>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="quiz.advanced" class="background pa-4 mb-2">
        <v-card-subtitle class="overline pa-0 primary--text font-weight-bold">Powerups Settings</v-card-subtitle>
        <v-col cols="12" class="d-flex flex-column flex-md-row justify-space-between">
          <v-switch
            :input-value="quiz.reducescore"
            @change="updateQuiz({ reducescore: $event })"
            :disabled="quiz.lifelines.length === 0"
            inset
            class="mr-4"
            :label="`${quiz.reducescore ? 'Reduce' : 'Do Not Reduce'} Score for Powerup Use`"
          ></v-switch>
          <v-slider
            :label="`Reduce Score by ${quiz.reducepercent}%`"
            :value="quiz.reducepercent"
            @change="updateQuiz({ reducepercent: $event })"
            :disabled="!quiz.reducescore || quiz.lifelines.length === 0"
            step="5"
            min="10"
            max="80"
            thumb-label="always"
            class="mt-4"
          ></v-slider>
        </v-col>
        <v-col cols="4" v-if="random">
          <v-card-subtitle class="overline primary--text font-weight-bold">Coming Soon</v-card-subtitle>
          <v-switch
            :input-value="quiz.randomlifeline"
            @change="updateQuiz({ randomlifeline: $event })"
            :disabled="quiz.lifelines.length === 0"
            inset
            :label="`Randomize Powerups ${quiz.randomlifeline ? 'On' : 'Off'} `"
          ></v-switch>
          <v-slider
            label="Powerup Frequency %"
            :value="quiz.lifelinefrequency"
            @change="updateQuiz({ lifelinefrequency: $event })"
            :disabled="!quiz.randomlifeline"
            step="5"
            min="10"
            max="100"
            thumb-label="always"
          ></v-slider>
          <v-slider
            label="Powerup Eligible Participants %"
            :value="quiz.lifelineeligible"
            @change="updateQuiz({ lifelineeligible: $event })"
            :disabled="!quiz.randomlifeline"
            step="5"
            min="0"
            max="100"
            thumb-label="always"
          ></v-slider>
        </v-col>
      </v-row>
      <quiz-form-action-bar
        @addQuiz="addQuiz"
        @updateQuiz="updateSettings"
        @deleteQuiz="deleteQuizFromFirebase"
        @closeQuizForm="setEditing"
        :quizid="quizid"
      ></quiz-form-action-bar>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions } from "vuex"
import QuizFormActionBar from "@/components/quiz/QuizFormActionBar.vue"
import QuizTypeCard from "@/components/quiz/QuizTypeCard"

export default {
  name: "QuizForm",
  props: {
    quiz: {
      default: () => {},
      type: Object,
    },
    quizid: {
      default: () => "",
      type: String,
    },
  },
  components: {
    QuizFormActionBar,
    QuizTypeCard,
  },
  data() {
    return {
      defaults: false,
      valid: true,
      isEditing: false,
      titlerule: [(v) => !!v || "title is required!"],
      descrule: [(v) => !!v || "Description is required!"],
      timerrule: [(v) => !!v || "Timer is required!"],
      random: false,
    }
  },
  methods: {
    ...mapActions(["updateQuiz", "updateCurrentQuiz"]),
    addQuiz() {
      if (this.$refs.quizform.validate()) {
        this.$emit("addQuiz")
      }
    },
    updateSettings() {
      if (this.$refs.quizform.validate()) {
        this.updateCurrentQuiz(this.quiz).then(() => this.$emit("closeQuizForm"))
      }
    },
    setEditing() {
      this.$emit("closeQuizForm")
    },

    deleteQuizFromFirebase() {
      this.$eventBus.$emit("deletequiz", this.quizid)
    },
  },
}
</script>
<style lang="scss" scoped>
.invalid {
  border-color: var(--v-error-base) !important;
}
.quizform {
  border: 1px solid var(--v-accent2-base);
  color: var(--v-primary-base);
}
</style>
