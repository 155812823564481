<template>
  <v-row align="center" justify="center" class="align-stretch" dense>
    <v-col cols="12" sm="4" v-for="card in cards" :key="card.title">
      <v-hover v-slot="{ hover }">
        <v-card
          outlined
          :elevation="hover ? 4 : 0"
          height="100%"
          width="100%"
          :color="hover || isActive(card) ? 'secondary white--text' : ''"
          @click.once="handleClick(card)"
        >
          <v-col cols="12" class="d-flex flex-md-row justify-space-between">
            <v-card-title class="text-h6 text-sm-h5 font-weight-bold">{{ card.short_title }}</v-card-title>
            <v-img
              :max-height="$vuetify.breakpoint.smAndUp ? 120 : 60"
              :max-width="$vuetify.breakpoint.smAndUp ? 90 : 60"
              contain
              :src="require('@/assets/' + card.image + '')"
            ></v-img>
          </v-col>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex"
export default {
  name: "QuizTypeCard",
  props: {
    quiz: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      cards: [
        {
          title: "Live Quiz",
          short_title: "Live",
          subtitle: "Presenter controls flow of questions and participants play live",
          image: "quiz.png",
          type: "L",
        },
        {
          title: "Classic Quiz",
          short_title: "Classic",
          subtitle: "Just an online test with time and live results dashboard",
          image: "poll.png",
          type: "C",
        },
        {
          title: "Simple Quiz",
          short_title: "Simple",
          subtitle: "Quiz with no time pressure. Participants answer at their own pace.",
          image: "poll.png",
          type: "S",
        },
      ],
    }
  },
  computed: {},
  methods: {
    ...mapActions(["updateQuiz", "updateCurrentQuiz"]),
    handleClick(card) {
      if (!this.quiz) {
        this.$router.push({ name: "Create", params: { type: card.type } })
      } else {
        let timerenabled = card.type === "S" ? false : true
        this.updateQuiz({ type: card.type, timerenabled: timerenabled })
      }
    },

    isActive(card) {
      return !this.quiz ? false : card.type === this.quiz.type ? true : false
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card {
  cursor: pointer;
  border: 1px solid var(--v-accent2-base);
}
</style>
