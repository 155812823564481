var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.quiz
    ? _c(
        "v-form",
        {
          ref: "quizform",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass:
                "d-flex flex-column pa-4 pa-md-8 quizform rounded-lg",
              class: !_vm.valid ? "invalid" : "",
              attrs: {
                outlined: _vm.$vuetify.breakpoint.mdAndUp,
                elevation: "0"
              }
            },
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5 primary--text font-weight-black" },
                [_vm._v(_vm._s(_vm.quizid ? "Edit " : "New ") + " Quiz")]
              ),
              _c("quiz-type-card", { attrs: { quiz: _vm.quiz } }),
              _c(
                "v-row",
                {
                  staticClass: "pa-4 my-2",
                  attrs: { justify: "space-between" }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card-subtitle",
                        {
                          staticClass:
                            "overline pa-0 primary--text font-weight-bold"
                        },
                        [_vm._v("Basic Details")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Title",
                          value: _vm.quiz.title,
                          rules: _vm.titlerule,
                          clearable: ""
                        },
                        on: {
                          blur: function($event) {
                            return _vm.updateQuiz({
                              title: $event.target.value
                            })
                          }
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: "Description",
                          value: _vm.quiz.description,
                          rules: _vm.descrule,
                          clearable: ""
                        },
                        on: {
                          blur: function($event) {
                            return _vm.updateQuiz({
                              description: $event.target.value
                            })
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _vm.quiz.timerenabled
                        ? _c("v-switch", {
                            attrs: {
                              "input-value": _vm.quiz.advanced,
                              inset: "",
                              label:
                                "Powerups " +
                                (_vm.quiz.advanced ? "Enabled" : "Disabled")
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateQuiz({ advanced: $event })
                              }
                            }
                          })
                        : _vm._e(),
                      _c("v-switch", {
                        attrs: {
                          "input-value": _vm.defaults,
                          inset: "",
                          label:
                            "Question Defaults " +
                            (_vm.defaults ? "Enabled" : "Disabled") +
                            " "
                        },
                        on: {
                          change: function($event) {
                            _vm.defaults = !_vm.defaults
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.defaults
                ? _c(
                    "v-row",
                    {
                      staticClass: "background pa-4 mb-2",
                      attrs: { "no-gutters": "" }
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card-subtitle",
                            {
                              staticClass:
                                "overline pa-0 primary--text font-weight-bold"
                            },
                            [_vm._v("Default For New Question")]
                          )
                        ],
                        1
                      ),
                      _vm.quiz.timerenabled
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "3" } },
                            [
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "mx-4",
                                  attrs: {
                                    value: _vm.quiz.defaultquestiontype,
                                    mandatory: "",
                                    row: _vm.$vuetify.breakpoint.smAndDown
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      dense: "",
                                      value: "T",
                                      label: "True/False"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.updateQuiz({
                                          defaultquestiontype: "T"
                                        })
                                      }
                                    }
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      dense: "",
                                      value: "M",
                                      label: "Multi-Choice"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.updateQuiz({
                                          defaultquestiontype: "M"
                                        })
                                      }
                                    }
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      dense: "",
                                      value: "P",
                                      label: "Poll"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.updateQuiz({
                                          defaultquestiontype: "P"
                                        })
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column flex-md-row",
                          attrs: { cols: "12", md: "9" }
                        },
                        [
                          _vm.quiz.timerenabled
                            ? _c("v-slider", {
                                staticClass: "mt-6",
                                attrs: {
                                  "hide-details": "",
                                  label: "Time (in secs)",
                                  value: _vm.quiz.timer,
                                  step: "5",
                                  min: "0",
                                  max: "300",
                                  "thumb-label": "always"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.updateQuiz({ timer: $event })
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.quiz.timerenabled
                            ? _c("v-slider", {
                                staticClass: "mt-6",
                                attrs: {
                                  "hide-details": "",
                                  label: "Points",
                                  value: _vm.quiz.points,
                                  step: "10",
                                  min: "10",
                                  max: "100",
                                  "thumb-label": "always"
                                },
                                on: {
                                  end: function($event) {
                                    return _vm.updateQuiz({ points: $event })
                                  }
                                }
                              })
                            : _vm._e(),
                          _c("v-slider", {
                            staticClass: "mt-6",
                            attrs: {
                              "hide-details": "",
                              label: "Answer Choices",
                              value: _vm.quiz.choices,
                              step: "1",
                              min: "2",
                              max: "6",
                              "thumb-label": "always"
                            },
                            on: {
                              end: function($event) {
                                return _vm.updateQuiz({ choices: $event })
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.quiz.advanced
                ? _c(
                    "v-row",
                    {
                      staticClass: "background pa-4 mb-2",
                      attrs: { "no-gutters": "" }
                    },
                    [
                      _c(
                        "v-card-subtitle",
                        {
                          staticClass:
                            "overline pa-0 primary--text font-weight-bold"
                        },
                        [_vm._v("Powerups")]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "d-flex flex-column flex-md-row justify-space-between",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("v-switch", {
                            staticClass: "mr-4",
                            attrs: {
                              "input-value": _vm.quiz.lifelines,
                              inset: "",
                              color: "primary",
                              label: "Answer Hints",
                              value: "hint"
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateQuiz({ lifelines: $event })
                              }
                            }
                          }),
                          _c("v-switch", {
                            staticClass: "mr-4",
                            attrs: {
                              "input-value": _vm.quiz.lifelines,
                              inset: "",
                              color: "primary",
                              label: "Erase Incorrect",
                              value: "eraser"
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateQuiz({ lifelines: $event })
                              }
                            }
                          }),
                          _c("v-slider", {
                            staticClass: "mt-4",
                            attrs: {
                              label: "Max Powerups (Per Participant)",
                              value: _vm.quiz.maxlifelines,
                              disabled: _vm.quiz.lifelines.length === 0,
                              step: "1",
                              min: "2",
                              max: "10",
                              "thumb-label": "always"
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateQuiz({ maxlifelines: $event })
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.quiz.advanced
                ? _c(
                    "v-row",
                    {
                      staticClass: "background pa-4 mb-2",
                      attrs: { "no-gutters": "" }
                    },
                    [
                      _c(
                        "v-card-subtitle",
                        {
                          staticClass:
                            "overline pa-0 primary--text font-weight-bold"
                        },
                        [_vm._v("Powerups Settings")]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "d-flex flex-column flex-md-row justify-space-between",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("v-switch", {
                            staticClass: "mr-4",
                            attrs: {
                              "input-value": _vm.quiz.reducescore,
                              disabled: _vm.quiz.lifelines.length === 0,
                              inset: "",
                              label:
                                (_vm.quiz.reducescore
                                  ? "Reduce"
                                  : "Do Not Reduce") + " Score for Powerup Use"
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateQuiz({ reducescore: $event })
                              }
                            }
                          }),
                          _c("v-slider", {
                            staticClass: "mt-4",
                            attrs: {
                              label:
                                "Reduce Score by " +
                                _vm.quiz.reducepercent +
                                "%",
                              value: _vm.quiz.reducepercent,
                              disabled:
                                !_vm.quiz.reducescore ||
                                _vm.quiz.lifelines.length === 0,
                              step: "5",
                              min: "10",
                              max: "80",
                              "thumb-label": "always"
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateQuiz({ reducepercent: $event })
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm.random
                        ? _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c(
                                "v-card-subtitle",
                                {
                                  staticClass:
                                    "overline primary--text font-weight-bold"
                                },
                                [_vm._v("Coming Soon")]
                              ),
                              _c("v-switch", {
                                attrs: {
                                  "input-value": _vm.quiz.randomlifeline,
                                  disabled: _vm.quiz.lifelines.length === 0,
                                  inset: "",
                                  label:
                                    "Randomize Powerups " +
                                    (_vm.quiz.randomlifeline ? "On" : "Off") +
                                    " "
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.updateQuiz({
                                      randomlifeline: $event
                                    })
                                  }
                                }
                              }),
                              _c("v-slider", {
                                attrs: {
                                  label: "Powerup Frequency %",
                                  value: _vm.quiz.lifelinefrequency,
                                  disabled: !_vm.quiz.randomlifeline,
                                  step: "5",
                                  min: "10",
                                  max: "100",
                                  "thumb-label": "always"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.updateQuiz({
                                      lifelinefrequency: $event
                                    })
                                  }
                                }
                              }),
                              _c("v-slider", {
                                attrs: {
                                  label: "Powerup Eligible Participants %",
                                  value: _vm.quiz.lifelineeligible,
                                  disabled: !_vm.quiz.randomlifeline,
                                  step: "5",
                                  min: "0",
                                  max: "100",
                                  "thumb-label": "always"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.updateQuiz({
                                      lifelineeligible: $event
                                    })
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c("quiz-form-action-bar", {
                attrs: { quizid: _vm.quizid },
                on: {
                  addQuiz: _vm.addQuiz,
                  updateQuiz: _vm.updateSettings,
                  deleteQuiz: _vm.deleteQuizFromFirebase,
                  closeQuizForm: _vm.setEditing
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }