var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-8" },
    [
      _vm.$vuetify.breakpoint.smAndUp
        ? _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              !_vm.quizid
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-2",
                          attrs: { color: "success" },
                          on: { click: _vm.addQuiz }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "plus", pull: "left" }
                          }),
                          _vm._v(" Create Quiz")
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-2",
                          attrs: { color: "primary", to: { name: "Quizzes" } }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "arrow-left", pull: "left" }
                          }),
                          _vm._v(" Back to Quizzes")
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.quizid
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-1 mx-xl-2",
                          attrs: { color: "success" },
                          on: { click: _vm.updateQuiz }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "save", pull: "left" }
                          }),
                          _vm._v(" Update Quiz")
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-1 mx-xl-2",
                          attrs: { color: "primary" },
                          on: { click: _vm.closeQuizForm }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "arrow-left", pull: "left" }
                          }),
                          _vm._v(" Back")
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.quizid
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-1 mx-xl-2",
                          attrs: { color: "error" },
                          on: { click: _vm.askConfirmation }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "times", pull: "left" }
                          }),
                          _vm._v(" Delete Quiz")
                        ],
                        1
                      ),
                      _vm.$vuetify.breakpoint.mdAndDown
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mx-1 mx-xl-2",
                              attrs: {
                                outlined: "",
                                color: "accent",
                                to: { name: "Quizzes" }
                              }
                            },
                            [_vm._v("Back to Quizzes")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.$vuetify.breakpoint.xs
        ? _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              !_vm.quizid
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { color: "success" },
                          on: { click: _vm.addQuiz }
                        },
                        [_vm._v("Create Quiz")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", to: { name: "Quizzes" } }
                        },
                        [_vm._v("Home")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.quizid
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { color: "success" },
                          on: { click: _vm.updateQuiz }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "save", pull: "left" }
                          }),
                          _vm._v(" Update")
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { color: "error" },
                          on: { click: _vm.askConfirmation }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "times", pull: "left" }
                          }),
                          _vm._v(" Delete")
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { icon: "", color: "warning" },
                          on: { click: _vm.closeQuizForm }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "arrow-left", pull: "left" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            color: "primary",
                            to: { name: "Quizzes" }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "dice-d20", pull: "left" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }