var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass: "align-stretch",
      attrs: { align: "center", justify: "center", dense: "" }
    },
    _vm._l(_vm.cards, function(card) {
      return _c(
        "v-col",
        { key: card.title, attrs: { cols: "12", sm: "4" } },
        [
          _c("v-hover", {
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var hover = ref.hover
                    return [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            outlined: "",
                            elevation: hover ? 4 : 0,
                            height: "100%",
                            width: "100%",
                            color:
                              hover || _vm.isActive(card)
                                ? "secondary white--text"
                                : ""
                          },
                          on: {
                            "~click": function($event) {
                              return _vm.handleClick(card)
                            }
                          }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "d-flex flex-md-row justify-space-between",
                              attrs: { cols: "12" }
                            },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "text-h6 text-sm-h5 font-weight-bold"
                                },
                                [_vm._v(_vm._s(card.short_title))]
                              ),
                              _c("v-img", {
                                attrs: {
                                  "max-height": _vm.$vuetify.breakpoint.smAndUp
                                    ? 120
                                    : 60,
                                  "max-width": _vm.$vuetify.breakpoint.smAndUp
                                    ? 90
                                    : 60,
                                  contain: "",
                                  src: require("@/assets/" + card.image + "")
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }