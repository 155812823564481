<template>
  <div class="mt-8">
    <v-row align="center" v-if="$vuetify.breakpoint.smAndUp">
      <v-col cols="auto" v-if="!quizid">
        <v-btn color="success" @click="addQuiz" class="ma-2">
          <font-awesome-icon icon="plus" pull="left"></font-awesome-icon>
          Create Quiz</v-btn
        >
        <v-btn color="primary" :to="{ name: 'Quizzes' }" class="ma-2">
          <font-awesome-icon icon="arrow-left" pull="left"></font-awesome-icon>
          Back to Quizzes</v-btn
        >
      </v-col>
      <v-col cols="auto" v-if="quizid">
        <v-btn class="mx-1 mx-xl-2" color="success" @click="updateQuiz">
          <font-awesome-icon icon="save" pull="left"></font-awesome-icon>
          Update Quiz</v-btn
        >
        <v-btn class="mx-1 mx-xl-2" color="primary" @click="closeQuizForm">
          <font-awesome-icon icon="arrow-left" pull="left"></font-awesome-icon>
          Back</v-btn
        >
      </v-col>
      <v-col cols="auto" v-if="quizid">
        <v-btn class="mx-1 mx-xl-2" color="error" @click="askConfirmation">
          <font-awesome-icon icon="times" pull="left"></font-awesome-icon>
          Delete Quiz</v-btn
        >
        <v-btn
          class="mx-1 mx-xl-2"
          v-if="$vuetify.breakpoint.mdAndDown"
          outlined
          color="accent"
          :to="{ name: 'Quizzes' }"
          >Back to Quizzes</v-btn
        >
      </v-col>
    </v-row>
    <v-row align="center" v-if="$vuetify.breakpoint.xs">
      <v-col cols="auto" v-if="!quizid">
        <v-btn color="success" @click="addQuiz" class="mr-2">Create Quiz</v-btn>
        <v-btn color="primary" :to="{ name: 'Quizzes' }">Home</v-btn>
      </v-col>
      <v-col cols="auto" v-if="quizid">
        <v-btn class="mr-2" color="success" @click="updateQuiz">
          <font-awesome-icon icon="save" pull="left"></font-awesome-icon>
          Update</v-btn
        >
        <v-btn class="mr-2" color="error" @click="askConfirmation">
          <font-awesome-icon icon="times" pull="left"></font-awesome-icon>
          Delete</v-btn
        >
        <v-btn icon class="mr-2" color="warning" @click="closeQuizForm">
          <font-awesome-icon icon="arrow-left" pull="left"></font-awesome-icon>
        </v-btn>
        <v-btn icon color="primary" :to="{ name: 'Quizzes' }">
          <font-awesome-icon icon="dice-d20" pull="left"></font-awesome-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "QuizFormActionBar",
  props: {
    quizid: {
      default: () => null,
      type: String,
    },
  },
  methods: {
    addQuiz() {
      this.$emit("addQuiz")
    },
    updateQuiz() {
      this.$emit("updateQuiz")
    },
    askConfirmation() {
      this.$eventBus.$emit("deletequiz", this.quizid)
    },
    closeQuizForm() {
      this.$emit("closeQuizForm")
    },
  },
}
</script>

<style lang="scss" scoped></style>
